import React from "react";

interface IProps {
  className?: string;
}

const BookACall = (props: IProps) => {
  return (
    <a
      target={"_blank"}
      rel="noreferrer"
      aria-label={"Open a new tab to book a 15 minute call"}
      className={
        "text-md   h-full w-full font-bold underline  transition-colors hover:text-brand-pink hover:no-underline  " +
        props.className
      }
      href={"https://calendly.com/scrumify-at/15min"}
    >
      Book a 15 min call
    </a>
  );
};

export default BookACall;
